export default defineNuxtRouteMiddleware((to, from) => {
  const store = useAuthStore();

  if (
    store.isAuthenticated ||
    (from.name === 'checkout-takeaway' && store.isGuest)
  ) {
    return navigateTo({ name: 'menu' });
  }
});
